import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { env } from '../environments/environment';
import { appRoutes } from './app.routes';
import { MarkdownModule } from 'ngx-markdown';
import { provideNgIconsConfig } from '@ng-icons/core';
import { provideUnirCore } from '@unir/core';
import { provideCrosscuttingAuth } from '@unir/core/auth/crosscutting';
export const appConfig: ApplicationConfig = {
	providers: [
		provideUnirCore({
			url: env.url,
			i18nAssets: ['/assets/i18n/']
		}),
		provideCrosscuttingAuth({
			oauthAllowedUrls: [
				env.url
			],
			loginOnDemand: false
		}),
		importProvidersFrom(
			BrowserModule,
			BrowserAnimationsModule,
			RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
			MarkdownModule.forRoot()
		),
		provideHttpClient(withInterceptorsFromDi()),
		provideNgIconsConfig({
			size: '1.5rem',
			color: '#0a5cf5'
		})
	]
};
