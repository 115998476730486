// Client version (use mayor and minor, let patch free for teamcity)
export const version = '1.0.1';

export interface Configuration {
	version: string;
	production: boolean;
	url: string;
	wss: string;
	onBootstrapAppInit?: () => Promise<void>;
}
